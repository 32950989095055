import React, { useState } from "react";
import logo from "../images/mompo-logo.png";
import English from "../images/english.png";
import español from "../images/espaniol.png";
import { NavLink, Link } from "react-router-dom";
import { HashLink, NavHashLink } from "react-router-hash-link";
import { motion } from "framer-motion";

import "./navbar.css";
function Navbar() {
  const [Click, setClick] = useState(false);
  const handelClick = () => setClick(!Click);
  const closemibilemanu = () => setClick(false);

  const toptobottom = {
    hidden: { y: -80 },
    show: {
      y: 0,
      transition: {
        ease: "easeInOut",
        duration: 0.6,
      },
    },
  };
  const enlanguage = () => {
    window.location.href = "/en/";
  };

  return (
    <>
      <motion.div
        id="top"
        className="mompo-navbar-wrapper"
        variants={toptobottom}
        initial="hidden"
        animate="show"
      >
        <div className="mompo-links-container-one mompo-mobile-link">
          <ul className="mompo-nav-container">
            <li className="mompo-nav-item labguageSelector">
              <span className="mompo-nav-link" onClick={enlanguage}>
                <img
                  src={English}
                  alt="EnglishFlag"
                  width={"16"}
                  height={"11"}
                />
                <span>English</span>
              </span>
            </li>
            <li className="mompo-nav-item">
              <NavLink
                to="/es/"
                onClick={closemibilemanu}
                className={({ isActive }) =>
                  isActive ? "active mompo-nav-link" : "mompo-nav-link"
                }
              >
                Principal
              </NavLink>
            </li>
            <li className="mompo-nav-item es">
              <NavLink
                to="/es/About"
                onClick={closemibilemanu}
                className={({ isActive }) =>
                  isActive ? "active mompo-nav-link" : "mompo-nav-link"
                }
              >
                Sobre nosotros
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="mompo-logo-container">
          <NavLink to="/es/">
            <img
              src={logo}
              className="mompo-logo"
              alt="logotipo de vino santo y vino de masa MOMPO"
              height={"120"}
            />
          </NavLink>
        </div>
        <div className="mompo-links-container-two mompo-mobile-link">
          <ul className="mompo-nav-container">
            <li className="mompo-nav-item">
              <a href="/es/#BestWine" className="mompo-nav-link">
                Mejor vino
              </a>
            </li>
            <li className="mompo-nav-item">
              <NavLink
                to="/es/Contact"
                onClick={closemibilemanu}
                className={({ isActive }) =>
                  isActive ? "active mompo-nav-link" : "mompo-nav-link"
                }
              >
                Contacto
              </NavLink>
            </li>
            <li className="mompo-nav-item labguageSelector">
              <span className="mompo-nav-link">
                <img
                  src={español}
                  alt="espanolFlag"
                  width={"16"}
                  height={"11"}
                />
                <span>Español</span>
              </span>
            </li>
          </ul>
        </div>
        <div
          className={
            Click
              ? "mompo-links-container-mobile mobileDisplay"
              : "mompo-links-container-mobile"
          }
        >
          <ul className="mompo-nav-container">
            <li className="mompo-nav-item">
              <NavLink
                to="/es/"
                onClick={closemibilemanu}
                className={({ isActive }) =>
                  isActive ? "active mompo-nav-link" : "mompo-nav-link"
                }
              >
                Principal
              </NavLink>
            </li>
            <li className="mompo-nav-item es">
              <NavLink
                to="/es/About"
                onClick={closemibilemanu}
                className={({ isActive }) =>
                  isActive ? "active mompo-nav-link" : "mompo-nav-link"
                }
              >
                Sobre nosotros
              </NavLink>
            </li>
            <li className="mompo-nav-item">
              <a href="/es/#BestWine" className="mompo-nav-link">
                Mejor vino
              </a>
            </li>
            <li className="mompo-nav-item">
              <NavLink
                to="/es/Contact"
                onClick={closemibilemanu}
                className={({ isActive }) =>
                  isActive ? "active mompo-nav-link" : "mompo-nav-link"
                }
              >
                Contacto
              </NavLink>
            </li>
            <li className="mompo-nav-item labguageSelector">
              <div className="mobile-language-selector">
                <span className="mompo-nav-link" onClick={enlanguage}>
                  <img
                    src={English}
                    alt="englishFlag"
                    width={"16"}
                    height={"11"}
                  />
                  <span>English</span>
                </span>
                <span className="mompo-nav-link">
                  <img
                    src={español}
                    alt="espanolFlag"
                    width={"16"}
                    height={"11"}
                  />
                  <span>Español</span>
                </span>
              </div>
            </li>
          </ul>
        </div>
        <div className="mompo-hamburder-menu" onClick={handelClick}>
          <i className={Click ? "fa fa-2x fa-times" : "fa fa-2x fa-bars"} />
        </div>
      </motion.div>
    </>
  );
}

export default Navbar;
