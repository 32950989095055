import React from "react";
import "./OurUniquness.css";
import mompoBottole from "../images/mompo.webp";
import badge from "../images/exclusiveBadge.webp";
import { motion } from "framer-motion";
import { useState } from "react";

function OurUniquness() {
  const [winebottle, setWinebottol] = useState(window.innerWidth);
  const container = {
    show: {
      transition: {
        staggerChildren: 0.05,
      },
    },
  };
  return (
    <motion.div
      className="OurUniquness-wrapper"
      variants={container}
      initial="hidden"
      animate="show"
    >
      <div className="OurUniquness-container">
        <div className="textsecond"></div>
        <div className="shape-conaainer">
          <div className="shape"></div>
          <div className="bottleimage">
            <motion.img
              src={mompoBottole}
              className="bottoleimage"
              whileInView="visible"
              viewport={{ once: false }}
              transition={{ duration: 1 }}
              variants={{
                visible: { opacity: 1, rotate: "10deg", x: 0 },
                hidden: {
                  opacity: 0.5,
                  rotate: "0deg",
                  x: winebottle < 500 ? 200 : 500,
                },
              }}
              alt="Imagen de botella de vino de acebo y vino de masa mompo."
              width={"auto"}
              height={"135%"}
            />
            <motion.img
              src={badge}
              className="badgeimages"
              alt="Wine badge"
              whileInView="visible"
              viewport={{ once: false }}
              transition={{ duration: 0.8, delay: 0.8 }}
              variants={{
                visible: { opacity: 1, transform: "rotate(10deg)" },
                hidden: { opacity: 0 },
              }}
              height={"150px"}
              width={"auto"}
            />
          </div>
        </div>
      </div>
      <div className="detailes-container">
        <div className="detailes-container-inner">
          <div className="detailes-inner">
            <motion.h2
              whileInView="visible"
              viewport={{ once: false }}
              transition={{ duration: 0.8 }}
              variants={{
                visible: { x: 0, opacity: 1 },
                hidden: { x: -300, opacity: 0 },
              }}
            >
              Mejor vino santo MOMPO
            </motion.h2>
            <motion.h4
              whileInView="visible"
              viewport={{ once: false }}
              transition={{ duration: 0.8, delay: 0.3 }}
              variants={{
                visible: { x: 0, opacity: 1 },
                hidden: { x: -300, opacity: 0 },
              }}
            >
              Vino de Misa
            </motion.h4>
            <motion.p
              whileInView="visible"
              viewport={{ once: false }}
              transition={{ duration: 0.8, delay: 0.32 }}
              variants={{
                visible: { y: 0, opacity: 1 },
                hidden: { y: 100, opacity: 0 },
              }}
            >
              <span className="compneyname">MOMPO </span>
              es una experencia divina para los devotos de la iglesia,
              aromática y elegante. Conecta con el espíritu santo a través de la
              bendita sangre de cristo.
            </motion.p>
            <div className="winefeachers">
              <motion.h5
                whileInView="visible"
                viewport={{ once: false }}
                transition={{ duration: 0.8, delay: 0.34 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 100, opacity: 0 },
                }}
              >
                Naturalmente dulce
              </motion.h5>
              <motion.p
                whileInView="visible"
                viewport={{ once: false }}
                transition={{ duration: 0.8, delay: 0.36 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 100, opacity: 0 },
                }}
              >
                Vino naturalmente dulce <b>sin azúcar añadido</b>, la dulzura
                proviene únicamente de las uvas Moscatel de alejandria.
              </motion.p>

              <motion.h5
                whileInView="visible"
                viewport={{ once: false }}
                transition={{ duration: 0.8, delay: 0.38 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 100, opacity: 0 },
                }}
              >
                Envejeciendo hacia la perfección
              </motion.h5>
              <motion.p
                whileInView="visible"
                viewport={{ once: false }}
                transition={{ duration: 0.8, delay: 0.4 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 100, opacity: 0 },
                }}
              >
                Un vino que nunca muere, MOMPO mejora su sabor en botella y
                durará décadas evolucionando hacia un vino mejor.
                <b>¡sin fecha de caducidad!</b>
              </motion.p>
              <motion.h5
                whileInView="visible"
                viewport={{ once: false }}
                transition={{ duration: 0.8, delay: 0.38 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 100, opacity: 0 },
                }}
              >
                Vino Santo
              </motion.h5>
              <motion.p
                whileInView="visible"
                viewport={{ once: false }}
                transition={{ duration: 0.8, delay: 0.4 }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: 100, opacity: 0 },
                }}
              >
                vino santo con <b>sello de aprobación</b> por parte de las
                autoridades eclesiásticas.
              </motion.p>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default OurUniquness;
