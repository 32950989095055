import React, { useLayoutEffect, useRef, useState, useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./howWineMade.css";
import graps from "../images/graps.webp";
import GrapeSqiser from "../images/Grape-sqiser.webp";
import grape1 from "../images/grape1.webp";
import grape2 from "../images/grape2.webp";
import grape3 from "../images/grape3.webp";
import wineBarrel from "../images/wineBarrelVinum.webp";
import oldimage1 from "../images/BODEGACALDERON037color.webp";
import oldimage2 from "../images/BODEGACALDERON038color.webp";
import oldimage3 from "../images/BODEGACALDERON040color.webp";
import { motion } from "framer-motion";

gsap.registerPlugin(ScrollTrigger);

function HowWineMadeVm() {
  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const main = useRef();
  const [yOffsetValue, setYOffsetValue] = useState(700);

  useLayoutEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth < 991;

      if (isMobile) {
        const mobileYMax = 1000; // The maximum y value for mobile view (opposite to the previous value)
        const mobileYMin = 1150; // The minimum y value for mobile view (opposite to the previous value)
        const mobileWidthMax = 350; // Width of window where yOffsetValue is 1000 (opposite to the previous value)
        const mobileWidthMin = 600; // Width of window where yOffsetValue is 1150 (opposite to the previous value)
        const yValue =
          ((mobileYMin - mobileYMax) * (window.innerWidth - mobileWidthMin)) /
            (mobileWidthMax - mobileWidthMin) +
          mobileYMax;

        setYOffsetValue(yValue);
      } else {
        setYOffsetValue(700); // Default y value for desktop view
      }
    };

    handleResize(); // Call handleResize on initial load

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useLayoutEffect(() => {
    const ctx = gsap.context((self) => {
      const grape1 = self.selector(".grape1");
      grape1.forEach((box) => {
        const containerWidth = box.parentNode.offsetWidth;
        const containerheight = box.parentNode.offsetHeight;
        const isMobile = window.innerWidth < 991;
        const xOffsetPercentage = isMobile ? 70 : 150;
        const scalePercentage = window.innerWidth < 500 ? 1.2 : 2;
        const xOffsetPixels = (containerWidth * xOffsetPercentage) / 100;
        const actualy = isMobile
          ? window.innerWidth < 600
            ? yOffsetValue
            : 1000
          : 700;
        gsap.to(box, {
          x: `-=${xOffsetPixels}%`,
          y: actualy,
          rotate: 140,
          scale: scalePercentage,
          scrollTrigger: {
            trigger: box,
            start: "top 20%",
            end: "top -40%",
            scrub: true,
          },
        });
      });
      const grape2 = self.selector(".grape2");
      grape2.forEach((box) => {
        const containerWidth = box.parentNode.offsetWidth;
        const isMobile = window.innerWidth < 991;
        const xOffsetPercentage = isMobile ? 70 : 150;
        const scalePercentage = window.innerWidth < 500 ? 1.2 : 2;
        const xOffsetPixels = (containerWidth * xOffsetPercentage) / 100;
        const actualy = isMobile
          ? window.innerWidth < 600
            ? yOffsetValue + 50
            : 1000
          : 700;
        gsap.to(box, {
          x: `-=${xOffsetPixels}%`,
          y: actualy,
          rotate: 180,
          scale: scalePercentage,
          scrollTrigger: {
            trigger: box,
            start: "top 20%",
            end: "top -40%",
            scrub: true,
          },
        });
      });
      const grape3 = self.selector(".grape3");
      grape3.forEach((box) => {
        const containerWidth = box.parentNode.offsetWidth;
        const isMobile = window.innerWidth < 991;
        const xOffsetPercentage = isMobile ? 70 : 150;
        const scalePercentage = window.innerWidth < 500 ? 1.2 : 2;
        const xOffsetPixels = (containerWidth * xOffsetPercentage) / 100;
        const actualy = isMobile
          ? window.innerWidth < 600
            ? yOffsetValue + 50
            : 1000
          : 700;
        gsap.to(box, {
          x: `-=${xOffsetPixels}%`,
          y: actualy,
          rotate: 200,
          scale: scalePercentage,
          scrollTrigger: {
            trigger: box,
            start: "top 22%",
            end: "top -40%",
            scrub: true,
          },
        });
      });
      const allGrape = self.selector(".all-grape");
      allGrape.forEach((box) => {
        gsap.to(box, {
          opacity: 0,
          scrollTrigger: {
            trigger: box,
            start: "top -40%",
            end: "top -60%",
            scrub: true,
          },
        });
      });
    }, main); // <- Scope!
    return () => ctx.revert(); // <- Cleanup!
  }, [yOffsetValue]);

  const stickyImageRef = useRef(null);
  const secondDivRef = useRef(null);
  /*   const thirdDivRef = useRef(null);
   */
  useEffect(() => {
    gsap.registerPlugin("ScrollTrigger");

    const stickyImage = stickyImageRef.current;
    const secondDiv = secondDivRef.current;
    /*     const thirdDiv = thirdDivRef.current;
     */
    const stickyImageHeight = stickyImage.clientHeight;
    const secondDivHeight = secondDiv.clientHeight;
    /*     const thirdDivHeight = thirdDiv.clientHeight;
     */
    gsap.to(stickyImage, {
      y: () => {
        const y = stickyImageHeight;
        /*         const y = thirdDiv.offsetTop - stickyImageHeight;
         */ return `max(0px, ${y}px)`;
      },
      ease: "none",
      scrollTrigger: {
        trigger: stickyImage,
        start: "top top",
        end: () => `+=${secondDivHeight}`,
        /* end: () => `+=${thirdDivHeight + secondDivHeight}`, */
        pin: true,
        pinSpacing: false,
        scrub: true,
      },
    });
  }, []);
  const container = {
    show: {
      transition: {
        staggerChildren: 0.05,
      },
    },
  };
  return (
    <>
      <motion.div
        className="howMad-Wrapper"
        variants={container}
        initial="hidden"
        animate="show"
      >
        <div className="howMad-grap-perent">
          <div className="howMad-grap-tree-img">
            <motion.img
              src={graps}
              style={{ width: "100%" }}
              whileInView="visible"
              viewport={{ once: false }}
              transition={{ duration: 0.8 }}
              variants={{
                visible: { scale: 1, opacity: 1 },
                hidden: { scale: 1.2, opacity: 0 },
              }}
              alt="grapes farming image"
              width={"100%"}
              height={"100%"}
            />

            <div className="overlay-white-top-"></div>
            <div className="overlay-white-left-"></div>
          </div>
          <div className="howMad-grap-tree-deatiles">
            <motion.h2
              whileInView="visible"
              viewport={{ once: false }}
              transition={{ duration: 0.8, delay: 0.12 }}
              variants={{
                visible: { x: 0, opacity: 1 },
                hidden: { x: 200, opacity: 0 },
              }}
            >
              Elegancia ancestral: vino moscatel de alejandría
            </motion.h2>
            <motion.h4
              whileInView="visible"
              viewport={{ once: false }}
              transition={{ duration: 0.8, delay: 0.14 }}
              variants={{
                visible: { x: 0, opacity: 1 },
                hidden: { x: 200, opacity: 0 },
              }}
            >
              Moscatel de alejandría uvas
            </motion.h4>
            <motion.p
              whileInView="visible"
              viewport={{ once: false }}
              transition={{ duration: 0.8, delay: 0.16 }}
              variants={{
                visible: { x: 0, opacity: 1 },
                hidden: { x: 200, opacity: 0 },
              }}
            >
              Descubre la magnífica historia de las uvas moscatel de alejandría:
              Originaria del norte de África, esta variedad única ha resistido
              la prueba del tiempo. Debido a su carácter y fragancia tan
              particulares son tan apreciadas por los consumidores que son las
              únicas uvas del mundo que se consumen tanto en vino como en uvas
              de mesa.
            </motion.p>
            <motion.p
              className="m-0"
              whileInView="visible"
              viewport={{ once: false }}
              transition={{ duration: 0.8, delay: 0.18 }}
              variants={{
                visible: { x: 0, opacity: 1 },
                hidden: { x: 200, opacity: 0 },
              }}
            >
              La uva moscatel de Alejandría se adaptó perfectamente en muchas
              zonas del mediterráneo pero encontró un lugar perfecto en la
              Comunidad Valenciana, donde se encuentra la mayor superficie de
              plantación de España y una de las más grandes de Europa.
            </motion.p>
          </div>
        </div>
        <div className="howMad-grap-swizer-perent">
          <div className="howMad-grap-swizer">
            <motion.img
              src={GrapeSqiser}
              whileInView="visible"
              viewport={{ once: false }}
              transition={{ duration: 0.8, delay: 0.3 }}
              variants={{
                visible: { scale: 1, opacity: 1 },
                hidden: { scale: 1.5, opacity: 0 },
              }}
              alt="grape Squeezer image"
              width={"100%"}
              height={"auto"}
            />
          </div>
          <div className="howMad-grap-swizer-deatiles">
            <motion.h2
              whileInView="visible"
              viewport={{ once: false }}
              transition={{ duration: 0.8, delay: 0.32 }}
              variants={{
                visible: { x: 0, opacity: 1 },
                hidden: { x: 200, opacity: 0 },
              }}
            >
              El arte del vino sagrado MOMPO
            </motion.h2>
            <motion.h4
              whileInView="visible"
              viewport={{ once: false }}
              transition={{ duration: 0.8, delay: 0.34 }}
              variants={{
                visible: { x: 0, opacity: 1 },
                hidden: { x: 200, opacity: 0 },
              }}
            >
              Cómo se hizo el vino
            </motion.h4>
            <motion.p
              whileInView="visible"
              viewport={{ once: false }}
              transition={{ duration: 0.8, delay: 0.36 }}
              variants={{
                visible: { x: 0, opacity: 1 },
                hidden: { x: 200, opacity: 0 },
              }}
            >
              Deléitese con el vino sagrado MOMPO, elaborado para la santidad
              espiritual. Nuestro viaje enológico comienza con las mejores uvas,
              seleccionadas meticulosamente. obteniendo el mosto conservando al
              máximo la intensidad frutal paramos la fermentación para conservar
              el dulzor natural del vino. El resultado es un vino que
              trasciende, una ofrenda de pureza. Con suma devoción, MOMPO
              entrelaza tradición y fe, produciendo vinos destinados a los
              momentos santificados dentro de la iglesia.
            </motion.p>
            <motion.p
              className="m-0"
              whileInView="visible"
              viewport={{ once: false }}
              transition={{ duration: 0.8, delay: 0.38 }}
              variants={{
                visible: { x: 0, opacity: 1 },
                hidden: { x: 200, opacity: 0 },
              }}
            >
              Eleve su experiencia espiritual con cada sorbo, un testimonio de
              la conexión divina nutrida a través de cada paso de la creación.
            </motion.p>
          </div>
        </div>
        <div ref={main}>
          <div className="grape1 all-grape">
            <img src={grape1} alt="grape" width={"auto"} height={"500"} />
          </div>
          <div className="grape2 all-grape">
            <img src={grape2} alt="grape" width={"auto"} height={"500"} />
          </div>
          <div className="grape3 all-grape">
            <img src={grape3} alt="grape" width={"auto"} height={"500"} />
          </div>
        </div>
      </motion.div>
      <motion.div
        className="berraleimages"
        variants={container}
        initial="hidden"
        animate="show"
      >
        <div className="berraleimages-container" ref={stickyImageRef}>
          <motion.img
            src={wineBarrel}
            width={"100%"}
            height={"100%"}
            whileInView="visible"
            viewport={{ once: false }}
            transition={{ duration: 1, delay: 0.5 }}
            variants={{
              visible: { scale: 1, opacity: 1 },
              hidden: { scale: 1.2, opacity: 0 },
            }}
            alt="wine barrel image"
          />
        </div>
        <div
          className="berraleimages-discription-perent left"
          ref={secondDivRef}
        >
          <div className="berraleimages-discription left">
            {/* Your content for the second div */}
            <motion.h2
              whileInView="visible"
              viewport={{ once: false }}
              transition={{ duration: 0.8 }}
              variants={{
                visible: { x: 0, opacity: 1 },
                hidden: { x: 200, opacity: 0 },
              }}
            >
              Legado sagrado: 170 años de vitivinicultura devocional de mompó
            </motion.h2>
            <motion.p
              whileInView="visible"
              viewport={{ once: false }}
              transition={{ duration: 0.8 }}
              variants={{
                visible: { x: 0, opacity: 1 },
                hidden: { x: 200, opacity: 0 },
              }}
            >
              Cinco generaciones de historia vitivinícola respaldan la calidad y
              el entusiasmo por el buen hacer de la familia mompo, de la que
              Mompo vino de misa fue el primero de sus productos de la empresa
              que se distribuyó fuera de Europa, muy probablemente el primer
              vino comercial distribuido en Asia fue Mompo, hasta donde sabemos
              no existe otro vino distribuido en Asia con una historia tan
              larga. .
            </motion.p>
            <motion.p
              whileInView="visible"
              viewport={{ once: false }}
              transition={{ duration: 0.8 }}
              variants={{
                visible: { x: 0, opacity: 1 },
                hidden: { x: 200, opacity: 0 },
              }}
            >
              Arraigado en una historia de resiliencia y fe, Mompó se erige como
              un testimonio del poder de la tradición y el vínculo sagrado entre
              el vino, la devoción y la espiritualidad. Con cada sorbo, los
              entusiastas participan de una herencia que abarca siglos, uniendo
              el pasado y el presente en una comunión sagrada de sabor y
              reverencia.
            </motion.p>
          </div>
        </div>
        {/* <div
          className="berraleimages-discription-perent right"
          ref={thirdDivRef}
        >
          <div className="berraleimages-discription right">
            <motion.p
              whileInView="visible"
              viewport={{ once: false }}
              transition={{ duration: 0.8 }}
              variants={{
                visible: { x: 0, opacity: 1 },
                hidden: { x: 200, opacity: 0 },
              }}
            >
              A lo largo del siglo XX, el vino de Mompó conservó la confianza de
              las autoridades eclesiásticas, convirtiéndose en la opción
              preferida para las celebraciones eucarísticas a nivel mundial. Su
              certificación duradera y su compromiso inquebrantable con la
              calidad han definido su legado. Los vinos de Mompó encapsulan no
              solo una artesanía excepcional, sino también la devoción y el
              cariño que se han nutrido durante generaciones.
            </motion.p>
            <motion.p
              whileInView="visible"
              viewport={{ once: false }}
              transition={{ duration: 0.8 }}
              variants={{
                visible: { x: 0, opacity: 1 },
                hidden: { x: 200, opacity: 0 },
              }}
            >
             
            </motion.p>
          </div>
        </div> */}
      </motion.div>
      <motion.div
        className="A170YearHistory-container"
        variants={container}
        initial="hidden"
        animate="show"
      >
        <div className="A170YearHistory-headding">
          <motion.h2
            className="m-0"
            whileInView="visible"
            viewport={{ once: false }}
            transition={{ duration: 0.5 }}
            variants={{
              visible: { y: 0, opacity: 1 },
              hidden: { y: 20, opacity: 0 },
            }}
          >
            170 años de historia
          </motion.h2>
          <motion.h4
            whileInView="visible"
            viewport={{ once: false }}
            transition={{ duration: 0.5, delay: 0.05 }}
            variants={{
              visible: { y: 0, opacity: 1 },
              hidden: { y: 20, opacity: 0 },
            }}
          >
            Desde 1850
          </motion.h4>
        </div>
        <div className="A170YearHistory">
          <div className="single-iimage-historey">
            <motion.img
              src={oldimage1}
              whileInView="visible"
              viewport={{ once: false }}
              transition={{ duration: 0.5, delay: 0.1 }}
              variants={{
                visible: { scale: 1, opacity: 1 },
                hidden: { scale: 1.5, opacity: 0 },
              }}
              alt="grap png"
            />
            <div className="overlay-white-top"></div>
            <div className="overlay-white-bottom"></div>
            <div className="overlay-white-right"></div>
            <div className="overlay-white-left"></div>
          </div>
          <div className="single-iimage-historey">
            <motion.img
              src={oldimage2}
              whileInView="visible"
              viewport={{ once: false }}
              transition={{ duration: 0.5, delay: 0.1 }}
              variants={{
                visible: { scale: 1, opacity: 1 },
                hidden: { scale: 1.5, opacity: 0 },
              }}
              alt="grap png"
            />
            <div className="overlay-white-top"></div>
            <div className="overlay-white-bottom"></div>
            <div className="overlay-white-right"></div>
            <div className="overlay-white-left"></div>
          </div>
          <div className="single-iimage-historey">
            <motion.img
              src={oldimage3}
              whileInView="visible"
              viewport={{ once: false }}
              transition={{ duration: 0.5, delay: 0.1 }}
              variants={{
                visible: { scale: 1, opacity: 1 },
                hidden: { scale: 1.5, opacity: 0 },
              }}
              alt="grap png"
            />
            <div className="overlay-white-top"></div>
            <div className="overlay-white-bottom"></div>
            <div className="overlay-white-right"></div>
            <div className="overlay-white-left"></div>
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default HowWineMadeVm;
