import React, { useState, useEffect } from "react";
import "./herosection.css";
import hero3 from "../images/hero-leyer-3.webp";
import hero2 from "../images/hero-leyer-2.webp";
import hero1 from "../images/hero-leyer-1.webp";
import cloud from "../images/cloud.webp";
import cloud1 from "../images/cloud2.webp";

import { motion } from "framer-motion";
function Herosection() {
  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const container = {
    show: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };
  const itemsbottomtotop = {
    hidden: { y: 500, opacity: 1 },
    show: {
      y: 0,
      opacity: 1,
      transition: {
        ease: [0.6, 0.99, 0.99, 0.95],
        duration: 1,
      },
    },
  };

  const toptobottom = {
    hidden: { y: -80 },
    show: {
      y: 700,
      transition: {
        ease: [0.6, 0.99, 0.99, 0.95],
        duration: 2,
      },
    },
  };
  const bottomtotop = {
    hidden: { y: 80, opacity: 1, rotate: 180 },
    show: {
      y: -700,
      opacity: 1,
      rotate: 180,
      transition: {
        ease: [0.6, 0.99, 0.99, 0.95],
        duration: 2,
      },
    },
  };
  const rightcloud = {
    hidden: { x: 200 },
    show: {
      x: 0,
      transition: {
        ease: "easeInOut",
        duration: 1,
      },
    },
  };
  const leftcloud = {
    hidden: { x: -200 },
    show: {
      x: 0,
      transition: {
        ease: "easeInOut",
        duration: 1,
      },
    },
  };
  const backremove = {
    hidden: { opacity: 1 },
    show: {
      opacity: 0,
      transition: {
        ease: "easeInOut",
        duration: 1,
      },
    },
  };

  return (
    <>
      <motion.section
        id="herosection"
        className="mompo-herosection-wrapper"
        variants={container}
        initial="hidden"
        animate="show"
      >
        <div className="overlayofherosection">
          <motion.img
            src={cloud}
            className="topcloud"
            variants={toptobottom}
            alt="Cloud"
          />
          <motion.img
            src={cloud}
            className="bottomcloud"
            variants={bottomtotop}
            alt="Cloud"
          />
          <motion.div
            className="backgroundwhitecolor"
            variants={backremove}
          ></motion.div>
        </div>
        <div className="mompo-hero-container">
          <div className="mompo-image-container">
            <div className="mompo-hero-text-continer">
              <div
                className="mompo-hero-text"
                style={{ transform: `translateY(${offsetY * 0.4}px)` }}
              >
                <motion.h1
                  className="mompo-hero-h1text"
                  variants={itemsbottomtotop}
                >
                  We are the eldest commercial holy
                  <br /> wine brand distributed in Asia
                  {/* Divine wines for devoted spirits
                  
                  and church celebrations */}
                </motion.h1>
                <motion.p
                  className="mompo-hero-ptext"
                  variants={itemsbottomtotop}
                >
                  Experience the divine collection of MOMPO holy wines, crafted
                  for
                  <br />
                  devoted spirits, church ceremonies, and joyous celebrations.
                  <br />
                  Elevate your moments together
                </motion.p>
              </div>
            </div>

            <div className="mompo-cloud-overlay">
              <motion.div variants={rightcloud} className="solocloud">
                <img
                  src={cloud1}
                  style={{
                    transform: `translateX(-${offsetY * 0.15}px) `,
                  }}
                  alt="Cloud"
                  width={"1000"}
                  height={"auto"}
                />
              </motion.div>
              <motion.div variants={leftcloud} className="solocloud1">
                <motion.img
                  src={cloud1}
                  style={{
                    transform: `translateX(${offsetY * 0.15}px)  rotate(25deg)`,
                  }}
                  alt="Cloud"
                  width={"1000"}
                  height={"auto"}
                />
              </motion.div>
            </div>
            <div className="heroimages-for-all">
              <motion.img
                src={hero1}
                className="backgroundheroimage mobile-setting-image front"
                style={{
                  transform: `translateY(-${offsetY * 0.2}px)`,
                  zIndex: 5,
                  top: "-10%",
                }}
                alt="holy wine and mompo mass wine poster"
                /*               variants={imagescale}
                 */
              />
              <motion.img
                src={hero2}
                className="backgroundheroimage mobile-setting-image middle"
                style={{
                  transform: `translateY(-${offsetY * 0.3}px)`,
                  zIndex: 3,
                  top: "-10%",
                }}
                alt="holy wine and mompo mass wine poster"
                /*               variants={imagescale}
                 */
              />
              <motion.img
                src={hero3}
                className="backgroundheroimage mobilebackgroundhero"
                style={{
                  transform: `translateY(-${offsetY * 0.1}px)`,
                  zIndex: 0,
                  top: "-20%",
                }}
                alt="holy wine and mompo mass wine poster"
                /*               variants={imagescale}
                 */
              />
            </div>
          </div>
        </div>
      </motion.section>
    </>
  );
}

export default Herosection;
