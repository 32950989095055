import React, { useState } from "react";
import "./products.css";
import { motion } from "framer-motion";
import mompoBottole from "../images/mompo.webp";
import vinumMissaeBottole from "../images/vinumMissae.webp";
import OurUniquness from "./OurUniquness_ES";
import HowWineMade from "./howWineMade_ES";
import OurUniqunessVm from "./OurUniquness_VM_ES";
import HowWineMadeVm from "./howWineMade_VM_ES";

function ProductsES() {
  const container = {
    show: {
      transition: {
        staggerChildren: 0.05,
      },
    },
  };
  const [selectedProduct, setSelectedProduct] = useState("mompoWine");
  function selectedProdyct(selectedIteam) {
    if (selectedProduct == "mompoWine") {
      if (selectedIteam == "mompoWine") {
        setSelectedProduct("mompoWine");
      } else {
        setSelectedProduct("mompoWine2");
      }
    } else {
      if (selectedIteam == "mompoWine") {
        setSelectedProduct("mompoWine");
      } else {
        setSelectedProduct("mompoWine2");
      }
    }
  }
  return (
    <>
      <motion.div
        className="productlist-continer"
        variants={container}
        initial="hidden"
        animate="show"
      >
        <div className="products-headding">
          <motion.h2
            whileInView="visible"
            viewport={{ once: false }}
            transition={{ duration: 0.8 }}
            variants={{
              visible: { y: 0, opacity: 1 },
              hidden: { y: 50, opacity: 0 },
            }}
          >
            Gama de productos
          </motion.h2>
          <motion.h4
            whileInView="visible"
            viewport={{ once: false }}
            transition={{ duration: 0.8, delay: 0.3 }}
            variants={{
              visible: { y: 0, opacity: 1 },
              hidden: { y: 50, opacity: 0 },
            }}
          >
            Todos nuestros productos
          </motion.h4>
        </div>
        <div className="productlist-main-box">
          <motion.div
            className={`productlist-products ${
              selectedProduct == "mompoWine" ? "selected" : ""
            }`}
            onClick={() => selectedProdyct("mompoWine")}
            whileInView="visible"
            viewport={{ once: false }}
            transition={{ duration: 0.8, delay: 0.5 }}
            variants={{
              visible: { y: 0, opacity: 1 },
              hidden: { y: 50, opacity: 0 },
            }}
          >
            <div className="productlist-products-inner">
              <div className="productlist-products-inner-img">
                <img
                  src={mompoBottole}
                  alt="Imagen de botella de vino de acebo y vino de masa mompo."
                />
                <div className="productlist-products-inner-img-shadow"></div>
              </div>
              <div className="productlist-products-inner-discription">
                <h4>Vino Mompó</h4>
                <p>Vino santo llamado vino MOMPO</p>
              </div>
              <div className="productlist-products-back"></div>
            </div>
          </motion.div>
          <motion.div
            className={`productlist-products ${
              selectedProduct == "mompoWine2" ? "selected" : ""
            }`}
            onClick={() => selectedProdyct("mompoWine2")}
            whileInView="visible"
            viewport={{ once: false }}
            transition={{ duration: 0.8, delay: 0.7 }}
            variants={{
              visible: { y: 0, opacity: 1 },
              hidden: { y: 50, opacity: 0 },
            }}
          >
            <div className="productlist-products-inner">
              <div className="productlist-products-inner-img">
                <img
                  src={vinumMissaeBottole}
                  alt="imagen de botella de vino de acebo"
                />
                <div className="productlist-products-inner-img-shadow"></div>
              </div>
              <div className="productlist-products-inner-discription">
                <h4>Vinum Missae</h4>
                <p>Vino santo llamado Vinum Missae</p>
              </div>
              <div className="productlist-products-back"></div>
            </div>
          </motion.div>
        </div>
      </motion.div>
      {selectedProduct == "mompoWine" ? (
        <>
          <OurUniquness />
          <HowWineMade />
        </>
      ) : (
        <>
          <OurUniqunessVm />
          <HowWineMadeVm />
        </>
      )}
    </>
  );
}

export default ProductsES;
